<template>
    <div>
        <div class="row">
            <div class="col-12">
                <h3 class="my-3"> Reviews </h3>
                <div class="row mb-2">
                    <div class="col-sm-12 col-md-8 mb-2 mb-md-0">
                        <form class="form-inline">
                            <label class="d-inline-flex mb-0 mr-2 align-items-center">
                                <b-form-select v-model="itemsPerPage" :options="pageOptions"></b-form-select>
                            </label>
                            <div class="form-group mb-0 mr-2">
                                <select id="status-select" @change="fetchItems()" v-model="sordBy"  class="custom-select">
                                    <option disabled selected>-Sort By-</option>
                                    <option value='1'>Original</option>
                                    <option value='0'>Created</option>
                                    <option :value='null'>Show all</option>
                                </select>
                            </div>
                            <button class="btn text-white btn-primary" type="button" @click.prevent="popupModalShow = true">
                                <i class="mdi mdi-plus-circle"></i> Review
                            </button>
                        </form>
                    </div>
                    <div class="col-sm-12 col-md-4">
                        <div class="">
                            <label class="d-block">
                                <b-form-input v-model="searchQuery" @input="updateSearchQuery" type="search" placeholder="Search..." class="form-control"></b-form-input>
                            </label>
                        </div>
                    </div>
                </div>
    
                <is-loading v-if="isLoading" />
                <div v-else class="row">
                    <div class="col-md-12">
                        <div class="mb-3">
                            <p class="mb-0">Showing {{paginationInfo}} results</p>
                        </div>
                    </div>
                    <div class="col-md-6" v-for="(review, index) in pageData.data" :key="index">
                        <review-card :item="review">
                            <template v-slot:actions>
                                <b-dropdown right toggle-class="text-body p-0" variant="black">
                                    <template v-slot:button-content>
                                        <i class="mdi mdi-dots-vertical font-20"></i>
                                    </template>
                                    <b-dropdown-item @click.prevent="initEdit(review)">Edit</b-dropdown-item>
                                    <b-dropdown-item @click.prevent="deleteItem(review.id)" >Delete</b-dropdown-item>
                                </b-dropdown>
                            </template>
                        </review-card>
                    </div>
                    <div class="col">
                        <div class="pb-4 paging_simple_numbers">
                            <b-pagination v-model="currentPage" class="pagination-rounded mb-0"
                            :total-rows="pageData.total" :limit="3" :per-page="itemsPerPage"></b-pagination>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    
        <b-modal  size="lg" centered id="modal-1" v-model="popupModalShow" title="Create Review"
         header-close-variant="primary" title-class="font-18" hide-footer>
            <review-form v-if="popupModalShow" @closeMe="initializeAll()"
            :editMode="editMode" :editItem="editItem"/>
        </b-modal>
    </div>
    </template>
    
    <script>
    
    import ReviewForm from "@/components/forms/ReviewForm.vue"
    import ReviewCard from '@/components/cards/ReviewCard.vue';
    import IsLoading from '@/components/IsLoading.vue';
    
    import _ from 'lodash';
    
    export default {
        components: {
            ReviewForm,
            ReviewCard,
            IsLoading,
        },
        data() {
            return {
                isLoading: false,
                popupModalShow:false,
                editMode: false,
                editItem: null,
                sordBy: null,
                searchQuery:"",
                pageOptions: [10, 25, 50, 100],
            };
        },
        computed: {
            pageData(){
                return this.$store.state.reviewList.pageData
            },
            currentPage: {
                get() {
                    return this.pageData.current_page
                },
                set(val) {
                    if(!this.pageData.current_page || this.pageData.current_page == val) return
                    this.fetchItems(val)
                }
            },
            itemsPerPage: {
                get() {
                    return +this.pageData.per_page || 10
                },
                set(val) {
                    this.fetchItems(1, val)
                }
            },
            paginationInfo(){
                if(!this.pageData.total) return '0 - 0 of 0'
                return `${this.currentPage * this.itemsPerPage - (this.itemsPerPage - 1)} 
                - ${this.pageData.total - this.currentPage * this.itemsPerPage > 0 
                ? this.currentPage * this.itemsPerPage : this.pageData.total}
                of ${this.pageData.total}`
            },
        },
        methods: {
            updateSearchQuery: _.debounce(function(string) {
                this.fetchItems()
            }, 2000),
            fetchItems(page, per_page = null){
                let payload = {page: page || 1, per_page: per_page || this.itemsPerPage }
                if(this.sordBy){ payload.is_real = this.sordBy }
                if(this.searchQuery){ payload.search = this.searchQuery }
                this.isLoading = true
                this.$store.dispatch("reviewList/fetchReviews", payload)
                .then(response => this.isLoading = false)
            },
            deleteItem(itemId){
                this.confirmDelete({
                    text: "You are about to delete the selected item!",
                }).then((result) => {
                    if (result.value) {
                        this.$store.dispatch("changeLoaderValue",true)
                        this.$http.delete(`/reviews/${itemId}/delete`)
                        .then((response) => {
                        this.$store.dispatch("changeLoaderValue", false)
                            if(response.data.success){
                                this.$store.commit(`reviewList/DELETE_REVIEW`, itemId)
                            }
                        })
                    }
                });
            },
            initEdit(item){
                this.editMode = true;
                this.editItem = {...item}
                this.popupModalShow = true  
            },
            initializeAll(){
                this.editMode = false;
                this.editItem = null;
                this.popupModalShow = false
            },
        },
        mounted(){
            this.fetchItems()
            this.$store.dispatch("productList/fetchProducts")
        }
    };
    </script>
    
    
    