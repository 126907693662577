<template>
 <div class="card">
    <div class="card-body">
        <div class="media" v-if="item.is_real">
            <div class="mr-3">
                <img :src="absoluteUrl(item.reviewer.image)" alt="user-image" class="rounded avatar-md" />
            </div>
            <div class="media-body">
                <h5 class="mb-1 mt-0">
                    {{item.reviewer.name}}
                </h5>
                <p class="text-muted mb-1">
                    <i class="mdi mdi-flag-checkered mr-1"></i>
                    <span v-if="item.reviewer.country">{{item.reviewer.country.name}} </span>
                </p>
                <p class="text-muted">
                    <i class="mdi mdi-star text-warning" v-for="rate in item.rating" :key="rate"></i>
                </p>
            </div>
            <div>
                <slot name="actions"></slot>
            </div>
        </div>
        <div class="media" v-else>
                <div class="mr-3" v-if="item.reviewer_image">
                <img :src="absoluteUrl(item.reviewer_image)" alt="user-image" class="rounded avatar-md" />
            </div>
            <div class="avatar-md mr-3" v-else>
                <div class="avatar-title bg-light rounded text-body font-20 font-weight-semibold">{{ item.reviewer_name.charAt(0) }}</div>
            </div>
            <div class="media-body">
                <h5 class="mb-1 mt-0">
                    {{item.reviewer_name}}
                </h5>
                <p class="text-muted mb-1">
                    <i class="mdi mdi-flag-checkered mr-1"></i>
                    <span v-if="item.country">{{item.country.name}} </span>
                </p>
                <p class="text-muted">
                    <i class="mdi mdi-star text-warning" v-for="rate in item.rating" :key="rate"></i>
                </p>
            </div>
                <b-dropdown right toggle-class="text-body p-0" variant="black">
                <template v-slot:button-content>
                    <i class="mdi mdi-dots-vertical font-20"></i>
                </template>
                <b-dropdown-item @click.prevent="onUpdate(review)">Edit</b-dropdown-item>
                <b-dropdown-item @click.prevent="deleteItem(item.id)" >Delete</b-dropdown-item>
            </b-dropdown>
        </div>
        <div class="">
            <p class="mb-0 font-14">
                {{item.content}} <router-link v-if="item.is_real" :to="'/orders/'+item.order_id">View Order</router-link>
            </p>
        </div>
        <hr class="my-2"/>
        <div class="text-muted">
            <div class="row">
                <div class="col-6">
                    <div>
                        <p class="text-truncate mb-1 font-14">Product</p>
                        <h5 class="my-0 font-14" v-if="item.product">
                            <router-link :to="'/products/'+item.product.id">{{ item.product.name }}</router-link>
                        </h5>
                    </div>
                </div>
                <div class="col-6">
                    <div class="text-right">
                        <p class="text-truncate mb-1 font-14">Reviewed Date</p>
                        <h5 class="my-0 font-14"><span class="badge badge-success"> {{ item.is_real ?  item.created_at : item.reviewed_at | date_parse(true)}}</span></h5>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
export default {
    props:{
        item:{
            type: Object,
            required: true
        }
    }
}
</script>

<style>

</style>